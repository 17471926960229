/**
 * Use this to copy the given string to the clipboard.
 */
export const copyToClipboard = text => {
  return navigator.clipboard.writeText(text)
}

/**
 * A helper to copy the given text to the clipboard while updating the target element with the success text momentarily.
 * This provides some feedback to the user that the copy action was succesful.
 *
 * @param {Object} e - Event object
 * @param {String} text - Text to copy to clipboard
 * @param {String} targetClassName - Class name of the target element that contains the copy that will update when the copy action is done
 * @param {String} successText - Success text to show momentarily after copying the text
 */
export const copyToClipboardAction = ({
  e,
  text,
  targetClassName,
  successText
}) => {
  copyToClipboard(text)

  const textContainer = e.currentTarget.querySelector(`.${targetClassName}`)
  if (textContainer) {
    const originalText = textContainer.textContent
    textContainer.textContent = successText

    setTimeout(() => {
      textContainer.textContent = originalText
    }, 1200)
  }
}
